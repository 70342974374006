"use client";
import { AxiosError } from "axios";
import { ErrorMessage, Form, Formik } from "formik";
import { useLocale, useTranslations } from "next-intl";
import { formValues } from "../../common/utils/validation";
import FormField from "./common/FormField";
import { createContactRequest } from "../../datastore/email/contact-request/contact-request.api";
import { ContactRequest } from "../../datastore/email/contact-request/contact-request.model";

function ContactUsForm() {
  const t = useTranslations();
  const locale = useLocale();
  const initialValues = new ContactRequest();
  initialValues.locale = locale;

  return (<section id="contact-form" className="p-main form-section">
    <h2 className="text-2xl md:text-4xl text-gold font-bold">{t('contact-form.title')}</h2>
    <span className="max-w-64 text-center">{t('contact-form.subtitle')}</span>
    <Formik
      onSubmit={async (v, h) => {
        const res = await createContactRequest(v);
        if (res instanceof AxiosError) {
          h.setFieldError("error", t("contact-form.submit-error"));
          return;
        }
        h.resetForm();
        h.setFieldValue("success", t('contact-form.success-message'));
      }}
      initialValues={formValues(initialValues)}
    >
      {({ values, isSubmitting }) => (
        <Form className="space-y-3 w-full lg:w-2/3 xl:w-1/2">
          <div className="flex flex-col lg:flex-row gap-4 items-center">
            <FormField
              label={t('labels.full-name')}
              name="fullName"
              disabled={isSubmitting}
              required
            />
            <FormField
              label={t('labels.email')}
              disabled={isSubmitting}
              name="email"
              type="email"
              required
            />
          </div>
          <div className="flex flex-col lg:flex-row gap-4 items-center">
            <FormField
              label={t('labels.contact-reason')}
              disabled={isSubmitting}
              name="contactReason"
            />
            <FormField
              label={t('labels.phone-number')}
              disabled={isSubmitting}
              name="phoneNumber"
              type="number"
              maxLength={10}
            />
          </div>
          <div>
            <FormField
              label={t('labels.message')}
              name="message"
              required
              component="textarea"
              rows={6}
              maxLength={500}
              className="resize-none w-full"
              disabled={isSubmitting}
            />
          </div>
          <span className="my-2"><b className="text-red-700">*</b> {t('forms.required-hint')}</span>
          <div className="flex flex-col mt-6 justify-center items-center">
            <ErrorMessage name="error" className="text-red-700" component={"span"} />
            <button className="link-btn w-full mt-2" type="submit" disabled={isSubmitting}>
              {isSubmitting ?
                <span aria-label="loading" className="h-8 block loading loading-infinity m-auto loading-lg text-gold" /> :
                <span className="h-8 block">
                  {t('contact-form.submit')}
                </span>
              }
            </button>
            {values.success && <span className="mt-4 text-gold font-bold">{values.success}</span>}
          </div>
        </Form>
      )}
    </Formik>
  </section>);
}

export default ContactUsForm;