import {
  MappingProfile,
  createMap,
  forMember,
  mapFrom
} from "@automapper/core";
import { ContactDto } from "./contact.dto";
import { Contact } from "./contact.model";

export const contactProfile: MappingProfile = (mapper) => {
  createMap<ContactDto, Contact>(
    mapper,
    "ContactDto",
    "Contact",
    forMember(
      (dest) => dest.id,
      mapFrom((src) => src.id),
    ),
    forMember(
      (dest) => dest.phone,
      mapFrom((src) => src.phone),
    ),
    forMember(
      (dest) => dest.email,
      mapFrom((src) => src.email),
    ),
  )
}

