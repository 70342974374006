
import { addProfile, createMapper, ModelIdentifier } from "@automapper/core";
import { pojos } from "@automapper/pojos";
import { authProfile } from "../datastore/auth/auth.profile";
import { gameRoomProfile } from "../datastore/game-room/game-room.profile";
import { jobProfile } from "../datastore/job/job.profile";
import { locationProfile } from "../datastore/location/location.profile";
import { imageProfile } from "./models/image/image.profile";
import { PageDto } from "./models/page/page.dto";
import { Page } from "./models/page/page.model";
import { pageProfile } from "./models/page/page.profile";
import { contactProfile } from "../datastore/contact/contact.profile";
import { serviceProfile } from "../datastore/service/service.profile";
import { blogPostProfile } from "../datastore/blog-post/blog-post.profile";
import { contactRequestProfile } from "../datastore/email/contact-request/contact-request.profile";

export const mapper = createMapper({
  strategyInitializer: pojos()
});

export function mapPage<T, K>(src: PageDto<T>, sourceIdentifier: ModelIdentifier<T>, destinationIdentifier: ModelIdentifier<K>): Page<K> {
  const pageable = mapper.map<PageDto<T>, Page<K>>(src, "PageDto", "Page");
  const content = mapper.mapArray<T, K>((pageable as unknown as Page<T>).content, sourceIdentifier, destinationIdentifier);
  pageable.content = content;
  return pageable;
}

addProfile(mapper, pageProfile);
addProfile(mapper, imageProfile);
addProfile(mapper, authProfile);
addProfile(mapper, gameRoomProfile);
addProfile(mapper, contactProfile);
addProfile(mapper, serviceProfile);
addProfile(mapper, locationProfile);
addProfile(mapper, blogPostProfile);
addProfile(mapper, jobProfile);
addProfile(mapper, contactRequestProfile);