import axios, { AxiosError, AxiosResponse } from 'axios'
import cookie from "cookie"
import { jwtDecode } from 'jwt-decode'
import lodashGet from 'lodash/get'
import { GetServerSidePropsContext } from 'next/types'
import qs from 'qs'
import { TokenDataRaw } from '../../datastore/auth/auth.dto'

const baseAxiosConfig = {
  baseURL: process.env.NEXT_PUBLIC_CMS_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  }
}

const clientAxiosConfig = {
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  }
}

export const ServerAPI = axios.create(baseAxiosConfig)

export const ClientAPI = axios.create(clientAxiosConfig)

/**
 * @description These pieces allow us to throw errors on connection timeouts
 * @see https://github.com/axios/axios/issues/647#issuecomment-459517694
 */
const getRequestAbortionPieces = () => {
  const abort = axios.CancelToken.source()
  const connectionTimeout = setTimeout(
    () => abort.cancel(`Connection timeout of ${baseAxiosConfig.timeout}ms.`),
    baseAxiosConfig.timeout
  )

  return { abort, connectionTimeout }
}

export async function get<T>(
  path: string,
  additionalHeaders: Record<string, string | undefined> = {},
  {
    parameters,
  }: { token?: string; parameters?: Record<string, unknown> } = {},
  axiosClient = ServerAPI
): Promise<AxiosResponse<T>> {
  const { abort, connectionTimeout } = getRequestAbortionPieces()

  return axiosClient
    .get<T>(`/api${path}`, {
      headers: { /* ...setAuthorizationHeader(token), */ ...additionalHeaders },
      cancelToken: abort.token,
      params: parameters,
      /**
       * @description paramsSerializer takes an array of query params that is usually
       * serialized like this '/api/?id[]=1&id[]=2' and converts it into '/api/?id=1&id=2'
       * to better work with the API
       * */
      paramsSerializer: (parameters_) => qs.stringify(parameters_, { arrayFormat: 'repeat' }),
    })
    .then((response) => {
      clearTimeout(connectionTimeout)
      return response
    })
    .catch((error) => {
      clearTimeout(connectionTimeout)
      throw error
    })
}

export async function post<T>(
  path: string,
  body: Record<string, unknown> | FormData,
  additionalHeaders: Record<string, string | undefined> = {},
  { withCredentials }: { token?: string; parameters?: Record<string, unknown>, withCredentials?: boolean } = {},
  axiosClient = ServerAPI
): Promise<AxiosResponse<T>> {
  const { abort, connectionTimeout } = getRequestAbortionPieces()

  return axiosClient
    .post<T>(`/api${path}`, body, {
      withCredentials,
      headers: { /* ...setAuthorizationHeader(token), */ ...additionalHeaders },
      cancelToken: abort.token,
    })
    .then((response) => {
      clearTimeout(connectionTimeout)
      return response
    })
    .catch((error) => {
      clearTimeout(connectionTimeout)
      throw error
    })
}

export async function patch<T>(
  path: string,
  body: Record<string, unknown>,
  additionalHeaders: Record<string, string | undefined> = {},
  { }: { token?: string; parameters?: Record<string, unknown> } = {},
  axiosClient = ServerAPI
): Promise<AxiosResponse<T>> {
  const { abort, connectionTimeout } = getRequestAbortionPieces()

  return axiosClient
    .patch<T>(`/api${path}`, body, {
      headers: { /* ...setAuthorizationHeader(token), */ ...additionalHeaders },
      cancelToken: abort.token,
    })
    .then((response) => {
      clearTimeout(connectionTimeout)
      return response
    })
    .catch((error) => {
      clearTimeout(connectionTimeout)
      throw error
    })
}

export async function put<T>(
  path: string,
  body: Record<string, unknown>,
  additionalHeaders: Record<string, string | undefined> = {},
  { }: { token?: string; parameters?: Record<string, unknown> } = {},
  axiosClient = ServerAPI
): Promise<AxiosResponse<T>> {
  const { abort, connectionTimeout } = getRequestAbortionPieces()

  return axiosClient
    .put<T>(`/api${path}`, body, {
      headers: { /* ...setAuthorizationHeader(token), */ ...additionalHeaders },
      cancelToken: abort.token,
    })
    .then((response) => {
      clearTimeout(connectionTimeout)
      return response
    })
    .catch((error) => {
      clearTimeout(connectionTimeout)
      throw error
    })
}

export async function httpDelete<T>(
  path: string,
  additionalHeaders: Record<string, string | undefined> = {},
  {
    parameters,
  }: { token?: string; parameters?: Record<string, unknown> } = {},
  axiosClient = ServerAPI
): Promise<AxiosResponse<T>> {
  const { abort, connectionTimeout } = getRequestAbortionPieces()

  return axiosClient
    .delete<T>(`/api${path}`, {
      headers: { /* ...setAuthorizationHeader(token), */ ...additionalHeaders },
      cancelToken: abort.token,
      params: parameters,
      /**
       * @description paramsSerializer takes an array of query params that is usually
       * serialized like this '/api/?id[]=1&id[]=2' and converts it into '/api/?id=1&id=2'
       * to better work with the API
       * */
      paramsSerializer: (parameters_) =>
        qs.stringify(parameters_, { arrayFormat: 'repeat' }),
    })
    .then((response) => {
      clearTimeout(connectionTimeout)
      return response
    })
    .catch((error) => {
      clearTimeout(connectionTimeout)
      throw error
    })
}


export function getResponseErrorMessage(errorObject: AxiosError): string {
  // _.get's third argument is the default message
  // if errorObject.response.error doesn't resolve, it means that the server is down thus "Service Unavailable"
  const errorMessage = lodashGet(
    errorObject,
    'response.data.message',
    "Service Unavailable" as string
  )

  return errorMessage
}

export function getNextApiUrl(ctx: GetServerSidePropsContext) {
  const protocol = ctx.req.headers['x-forwarded-proto'] || 'http';
  const host = ctx.req.headers.host;
  return `${protocol}://api${host}/api`;
}

export function getReqToken(ctx: GetServerSidePropsContext) {
  const cookies = cookie.parse(ctx.req.headers.cookie || '');
  const token = cookies["token"];
  return token;
}

export function isValidAuthToken(token: string | undefined) {
  if (!token) {
    return false
  }
  const jwt = jwtDecode<TokenDataRaw>(token)
  const currentTime = new Date().getTime() / 1000

  // Valid if jwt expiry is in the future
  return currentTime < jwt.exp

}

/* function setAuthorizationHeader(token: string | undefined) {
  if (isValidAuthToken(token)) {
    return { Authorization: `Bearer ${token}` }
  }

  return { Authorization: '' }
} */