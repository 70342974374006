import { createMap, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { LoginDto, LoginResponseDto, TokenDataRaw } from "./auth.dto";
import { Login, LoginResponse, TokenData } from "./auth.model";

export const authProfile: MappingProfile = (mapper) => {
  createMap<LoginResponseDto, LoginResponse>(
    mapper,
    "LoginResponseDto",
    "LoginResponse",
    forMember(
      (dest) => dest.token,
      mapFrom((src) => src.token),
    ),
    forMember(
      (dest) => dest.refreshToken,
      mapFrom((src) => src.refreshToken),
    ),
  );

  createMap<Login, LoginDto>(
    mapper,
    "Login",
    "LoginDto",
    forMember(
      (dest) => dest.email,
      mapFrom((src) => src.email),
    ),
    forMember(
      (dest) => dest.password,
      mapFrom((src) => src.password),
    ),
  );

  createMap<TokenDataRaw, TokenData>(
    mapper,
    "TokenDataRaw",
    "TokenData",
    forMember(
      (dest) => dest.email,
      mapFrom((src) => src.sub),
    ),
    forMember(
      (dest) => dest.firstName,
      mapFrom((src) => src.firstName ?? ""),
    ),
    forMember(
      (dest) => dest.lastName,
      mapFrom((src) => src.lastName ?? ""),
    ),
    forMember(
      (dest) => dest.fullName,
      mapFrom((src) => `${src.firstName} ${src.lastName}`.trim()),
    ),
    forMember(
      (dest) => dest.roles,
      mapFrom((src) => src.roles ?? []),
    ),
    forMember(
      (dest) => dest.permissions,
      mapFrom((src) => src.permissions ?? []),
    ),
    forMember(
      (dest) => dest.expiresAt,
      mapFrom((src) => new Date(src.exp * 1000)),
    ),
    forMember(
      (dest) => dest.issuedAt,
      mapFrom((src) => new Date(src.iat * 1000)),
    ),
    forMember(
      (dest) => dest.tokenType,
      mapFrom((src) => src.tokenType),
    ),
    forMember(
      (dest) => dest.issuer,
      mapFrom((src) => src.iss),
    ),
  );

};
