/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessage, Field, FieldAttributes } from "formik";

type FormFieldProps<T = any> = FieldAttributes<T> & {
  label: string;
  labelClassName?: string;
  required?: boolean;
}

function FormField<T = any>({ label, labelClassName = "", required = false, className = "", ...props }: FormFieldProps<T>) {

  return (<label className={`flex flex-col w-full ${labelClassName}`}>
    <span className="font-roboto">{label} {required && <b className="text-red-700">*</b>}</span>
    <Field
      maxLength={255}
      required={required}
      className={`p-2 bg-light text-dark rounded-sm ${className}`}
      {...props}
    />
    <ErrorMessage name="name" className="text-red-500" />

  </label>);
}

export default FormField;