import {
  MappingProfile,
  createMap,
  forMember,
  mapFrom
} from "@automapper/core";
import { PageDto } from "./page.dto";
import { Page } from "./page.model";

export const pageProfile: MappingProfile = (mapper) => {
  createMap<PageDto, Page>(
    mapper,
    "PageDto",
    "Page",
    forMember(
      (dest) => dest.content,
      mapFrom((src) => src.data),
    ),
    forMember(
      (dest) => dest.page,
      mapFrom((src) => src.meta.pagination.page),
    ),
    forMember(
      (dest) => dest.empty,
      mapFrom((src) => (src.data?.length ?? 0) === 0),
    ),
    forMember(
      (dest) => dest.first,
      mapFrom((src) => src.meta.pagination.page === 1),
    ),
    forMember(
      (dest) => dest.last,
      mapFrom((src) => src.meta.pagination.page === src.meta.pagination.pageCount),
    ),
    forMember(
      (dest) => dest.pageSize,
      mapFrom((src) => src.meta.pagination.pageSize),
    ),
    forMember(
      (dest) => dest.totalElements,
      mapFrom((src) => src.meta.pagination.total),
    ),
    forMember(
      (dest) => dest.totalPages,
      mapFrom((src) => src.meta.pagination.pageCount),
    ),
  )
}

