import {
  MappingProfile,
  createMap,
  forMember,
  mapFrom
} from "@automapper/core";
import { GameRoomDto, GameRoomServiceDto } from "./game-room.dto";
import { GameRoom, GameRoomService } from "./game-room.model";
import { ImageDto } from "../../common/models/image/image.dto";
import { Image } from "../../common/models/image/image.model";
import { LocationDto } from "../location/location.dto";
import { Location } from "../location/location.model";
import { JobDto } from "../job/job.dto";
import { Job } from "../job/job.model";

export const gameRoomProfile: MappingProfile = (mapper) => {
  createMap<GameRoomDto, GameRoom>(
    mapper,
    "GameRoomDto",
    "GameRoom",
    forMember(
      (dest) => dest.id,
      mapFrom((src) => src.id),
    ),
    forMember(
      (dest) => dest.name,
      mapFrom((src) => src.name),
    ),
    forMember(
      (dest) => dest.closeTime,
      mapFrom((src) => src.close_time?.slice(0, 5) ?? null),
    ),
    forMember(
      (dest) => dest.openTime,
      mapFrom((src) => src.open_time?.slice(0, 5) ?? null),
    ),
    forMember(
      (dest) => dest.company,
      mapFrom((src) => src.company),
    ),
    forMember(
      (dest) => dest.images,
      mapFrom((src) => mapper.mapArray<ImageDto, Image>(src.images ?? [], "ImageDto", "Image")),
    ),
    forMember(
      (dest) => dest.location,
      mapFrom((src) => src.location ? mapper.map<LocationDto, Location>(src.location, "LocationDto", "Location") : null),
    ),
    forMember(
      (dest) => dest.jobs,
      mapFrom((src) => mapper.mapArray<JobDto, Job>(src.jobs ?? [], "JobDto", "Job")),
    ),
    forMember(
      (dest) => dest.gameRoomServices,
      mapFrom((src) => mapper.mapArray<GameRoomServiceDto, GameRoomService>(src.on_site_services ?? [], "GameRoomServiceDto", "GameRoomService")),
    ),
  )

  createMap<GameRoomServiceDto, GameRoomService>(
    mapper,
    "GameRoomServiceDto",
    "GameRoomService",
    forMember(
      (dest) => dest.id,
      mapFrom((src) => src.id),
    ),
    forMember(
      (dest) => dest.name,
      mapFrom((src) => src.name),
    ),
    forMember(
      (dest) => dest.description,
      mapFrom((src) => src.description),
    ),
    forMember(
      (dest) => dest.icon,
      mapFrom((src) => src.icon),
    ),
    forMember(
      (dest) => dest.image,
      mapFrom((src) => src.image ? mapper.map<ImageDto, Image>(src.image, "ImageDto", "Image") : null),
    ),
  )
}

