import {
  MappingProfile,
  createMap,
  forMember,
  mapFrom
} from "@automapper/core";
import { ImageDto } from "./image.dto";
import { Image } from "./image.model";

export const imageProfile: MappingProfile = (mapper) => {
  createMap<ImageDto, Image>(
    mapper,
    "ImageDto",
    "Image",
    forMember(
      (dest) => dest.id,
      mapFrom((src) => src.id),
    ),
    forMember(
      (dest) => dest.name,
      mapFrom((src) => src.name),
    ),
    forMember(
      (dest) => dest.height,
      mapFrom((src) => src.height),
    ),
    forMember(
      (dest) => dest.width,
      mapFrom((src) => src.width),
    ),
    forMember(
      (dest) => dest.url,
      mapFrom((src) => src.url ? process.env.NEXT_PUBLIC_CMS_URL + src.url : ""),
    ),
    forMember(
      (dest) => dest.thumbnail,
      mapFrom((src) => ({
        name: src.formats?.thumbnail?.name,
        url: src.formats?.thumbnail?.url ? process.env.NEXT_PUBLIC_CMS_URL + src.formats?.thumbnail?.url : "",
        width: src.formats?.thumbnail?.width,
        height: src.formats?.thumbnail?.height,
      })),
    ),

  )
}

