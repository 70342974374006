import { MappingProfile, createMap, forMember, mapFrom } from "@automapper/core"
import { ContactRequestDto } from "./contact-request.dto"
import { ContactRequest } from "./contact-request.model"

export const contactRequestProfile: MappingProfile = (mapper) => {
  createMap<ContactRequest, ContactRequestDto>(
    mapper,
    "ContactRequest",
    "ContactRequestDto",
    forMember(
      (dest) => dest.contactReason,
      mapFrom((src) => src.contactReason ?? ""),
    ),
    forMember(
      (dest) => dest.phoneNumber,
      mapFrom((src) => String(src.phoneNumber ?? "")),
    ),
    forMember(
      (dest) => dest.email,
      mapFrom((src) => src.email),
    ),
    forMember(
      (dest) => dest.fullName,
      mapFrom((src) => src.fullName),
    ),
    forMember(
      (dest) => dest.locale,
      mapFrom((src) => src.locale),
    ),
    forMember(
      (dest) => dest.message,
      mapFrom((src) => src.message),
    ),
  )

}
