import {
  MappingProfile,
  createMap,
  forMember,
  mapFrom
} from "@automapper/core";
import { ImageDto } from "../../common/models/image/image.dto";
import { Image } from "../../common/models/image/image.model";
import { BlogPostDto } from "./blog-post.dto";
import { BlogPost } from "./blog-post.model";

export const blogPostProfile: MappingProfile = (mapper) => {
  createMap<BlogPostDto, BlogPost>(
    mapper,
    "BlogPostDto",
    "BlogPost",
    forMember(
      (dest) => dest.id,
      mapFrom((src) => src.id),
    ),
    forMember(
      (dest) => dest.title,
      mapFrom((src) => src.title),
    ),
    forMember(
      (dest) => dest.date,
      mapFrom((src) => src.date ? new Date(src.date) : null),
    ),
    forMember(
      (dest) => dest.description,
      mapFrom((src) => src.description ?? ""),
    ),
    forMember(
      (dest) => dest.cover,
      mapFrom((src) => src.cover ? mapper.map<ImageDto, Image>(src.cover, "ImageDto", "Image") : null),
    ),
    forMember(
      (dest) => dest.content,
      mapFrom((src) => src.content),
    ),
  )

}

