import {
  MappingProfile,
  createMap,
  forMember,
  mapFrom
} from "@automapper/core";
import { ServiceDto } from "./service.dto";
import { Service } from "./service.model";
import { ImageDto } from "../../common/models/image/image.dto";
import { Image } from "../../common/models/image/image.model";

export const serviceProfile: MappingProfile = (mapper) => {
  createMap<ServiceDto, Service>(
    mapper,
    "ServiceDto",
    "Service",
    forMember(
      (dest) => dest.id,
      mapFrom((src) => src.id),
    ),
    forMember(
      (dest) => dest.name,
      mapFrom((src) => src.name),
    ),
    forMember(
      (dest) => dest.description,
      mapFrom((src) => src.description),
    ),
    forMember(
      (dest) => dest.target,
      mapFrom((src) => src.target),
    ),
    forMember(
      (dest) => dest.featured,
      mapFrom((src) => src.featured),
    ),
    forMember(
      (dest) => dest.cover,
      mapFrom((src) => src.cover ? mapper.map<ImageDto, Image>(src.cover, "ImageDto", "Image") : null),
    ),

  )
}

