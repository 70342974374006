import { AxiosError } from "axios";
import { ContactRequestDto } from "./contact-request.dto";
import { ContactRequest } from "./contact-request.model";
import { ClientAPI, post } from "../../../common/utils/api";
import { mapper } from "../../../common/mapper";

export async function createContactRequest(req: ContactRequest): Promise<void | AxiosError> {
  const body = mapper.map<ContactRequest, ContactRequestDto>(req, "ContactRequest", "ContactRequestDto");
  try {
    await post<ContactRequestDto>(`/contact-request`,
      body,
      undefined,
      undefined,
      ClientAPI
    );
  } catch (error) {
    return error as AxiosError
  }
}