import {
  MappingProfile,
  createMap,
  forMember,
  mapFrom
} from "@automapper/core";
import { LocationDto } from "../location/location.dto";
import { Location } from "../location/location.model";
import { JobDto } from "./job.dto";
import { Job } from "./job.model";

export const jobProfile: MappingProfile = (mapper) => {
  createMap<JobDto, Job>(
    mapper,
    "JobDto",
    "Job",
    forMember(
      (dest) => dest.id,
      mapFrom((src) => src.id),
    ),
    forMember(
      (dest) => dest.name,
      mapFrom((src) => src.name),
    ),
    forMember(
      (dest) => dest.content,
      mapFrom((src) => src.content),
    ),
    forMember(
      (dest) => dest.requirements,
      mapFrom((src) => (src.requirements ?? "").split("\n")),
    ),
    forMember(
      (dest) => dest.employer,
      mapFrom((src) => src.employer),
    ),
    forMember(
      (dest) => dest.activityHours,
      mapFrom((src) => src.activity_hours),
    ),
    forMember(
      (dest) => dest.location,
      mapFrom((src) => src.location ? mapper.map<LocationDto, Location>(src.location, "LocationDto", "Location") : null),
    )
  )
}

